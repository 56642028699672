$(document).on('turbolinks:load', function () {
    let outcomeSelect = $('#company_outcome_detail_outcome_indicator_id')
    let detailsField = $('#company_outcome_detail_reported_details')
    let dateReportedField = $('.company_outcome_detail_date_reported')
    if(outcomeSelect.length) {
        pollFieldInformation(outcomeSelect.val(), detailsField, dateReportedField)

        outcomeSelect.on('change', (e) => {
            const outcome_id = e.target.value;
            pollFieldInformation(outcome_id, detailsField, dateReportedField)
        })
    }
});

const pollFieldInformation = (outcomeIndicatorId, detailsField, dateReportedField) => {
    const url = `${window.location.origin}/nl/company_outcome_details/poll_outcome_indicator_info`;

    $.ajax({
        type: 'GET',
        url: url,
        data: { outcome_indicator_id: outcomeIndicatorId },
        success: (json) => {
            detailsField.val(detailsField.val() || json['details']);
            if(json['show_date_reported']) {
                dateReportedField.removeClass('d-none')
            } else {
                dateReportedField.addClass('d-none')
            }
        }
    })
}
