$(document).on('turbolinks:load', function () {

    let checkbox = $('#outcome_indicator_applicable_for_closure')
    let field = $('#outcome_indicator_termination_type')
    enable_field(checkbox.prop('checked'), field)
    checkbox.on('change', () => {
        enable_field(checkbox.prop('checked'), field)
    })

})

const enable_field = (enabled, field) => {
    if(enabled) {
        field.prop( "disabled", false );
        field.removeClass('disabled-select');
        field.toggleClass('required');
    } else {
        field.prop( "disabled", true );
        field.addClass('disabled-select');
        field.toggleClass('required');
    }
}