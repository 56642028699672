$(document).on('turbolinks:load', function () {
    let caregiver_form_field = $('#new_user input[data="caregiver"]');
    let triage_form_field = $('#new_user input[data="triage"]');
    let freelance_form_field = $('#new_user input[id="user_caregiver_feature_attributes_freelance"]');
    let online_form_field = $('#user_gives_online_sessions');
    let education_form_field = $('#user_education_level_id');
    let path = $(location).attr('pathname').split('/')
    let action = path.pop();
    let resource = path.pop();

    const disable_online_form_field = function () {
        online_form_field.val("").change();
        online_form_field.prop('disabled', true);
    };

    const disable_education_form_field = function () {
        education_form_field.val("").change();
        education_form_field.prop('disabled', true);
    };


    if (action !== 'edit' && resource !== 'patient_records') {
        if (!caregiver_form_field.prop('checked') && !triage_form_field.prop('checked')) {
            disable_online_form_field();
            disable_education_form_field();
        }
    }

    caregiver_form_field.change(function () {
        if (this.checked) {
            freelance_form_field.prop('disabled', false);
            online_form_field.prop('disabled', false);
            education_form_field.prop('disabled', false);
        } else {
            freelance_form_field.prop('disabled', true);
            freelance_form_field.prop('checked', false);
            disable_online_form_field();
            disable_education_form_field();
        }
    });

    triage_form_field.change(function () {
        if (this.checked) {
            online_form_field.prop('disabled', false);
            education_form_field.prop('disabled', false);
        } else {
            disable_online_form_field();
            disable_education_form_field();
        }
    });

});