$(document).on('turbolinks:load', function () {
    const form = $(".user-visibility-ajax-form");
    const target = $(".user-visibility-reasons")

    form.on("ajax:success", (event) => {
        const [_data, _status, xhr] = event.detail;
        target.html(xhr.responseText);
    });

    form.on("ajax:error", (event) => {
        const [_data, status, _xhr] = event.detail;
        target.html("<h1>" + status + "</h1>");
    });
});