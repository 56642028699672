$(document).on('turbolinks:load', function () {
    let new_patient_record_company_form_field = $('#patient_record_company_id');
    let new_patient_record_trajectory_select = $('select#patient_record_trajectory_id');

    let import_csv_company_form_field = $('#company');
    let import_csv_trajectory_select = $('select#trajectory');

    const set_select_options = function(select, url, data) {
        $.ajax({
            type: 'GET',
            url: url,
            data: data,
            success: function(json) {
                select.empty();
                select.append("<option value>"+json.empty_option+"</option>");
                json.options.forEach(item => {
                    let option_elem = document.createElement('option');
                    option_elem.value = item.id;
                    option_elem.innerHTML = item.name;
                    select.append(option_elem);
                });
            }
        });
    };

    const set_load_options = function() {
        const current_url = $(location).attr('pathname').split('/').pop();
        const url = 'upload_patient_records';
        if(current_url == url) {
            const company_id = import_csv_company_form_field.val();
            const url = '/upload_patient_records/trajectory_field';
            const data = {'company_id': company_id};
            set_select_options(import_csv_trajectory_select, url, data);
        };
    };

    set_load_options();

    new_patient_record_company_form_field.change(function(){
        const company_id = new_patient_record_company_form_field.val();
        const url = '/patient_records/new/trajectory_field';
        const data = {'company_id': company_id};
        set_select_options(new_patient_record_trajectory_select, url, data);
    });

    import_csv_company_form_field.change(function(){
        const company_id = import_csv_company_form_field.val();
        const url = '/upload_patient_records/trajectory_field';
        const data = {'company_id': company_id};
        set_select_options(import_csv_trajectory_select, url, data);
    });
});

