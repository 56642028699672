$(document).on('turbolinks:load', function () {
    let termination_form_field_type = $('#patient_record_termination_type');
    let outcome_indicator_select = $('#patient_record_status_outcome_indicator_id');

    const set_select_options = function(select, url, data) {
        $.ajax({
            type: 'GET',
            url: url,
            data: data,
            success: function(json) {
                select.empty();
                select.append("<option value>"+json.empty_option+"</option>");
                json.options.forEach(item => {
                    let option_elem = document.createElement('option');
                    option_elem.value = item.id;
                    option_elem.innerHTML = item.name;
                    select.append(option_elem);
                });
            }
        });
    };

    termination_form_field_type.change(function(){
        const termination_type = termination_form_field_type.val();
        const url = $(location).attr('pathname') + '/outcome_indicator_field';
        const data = {'termination_type': termination_type};
        set_select_options(outcome_indicator_select, url, data);
    });
});

