$(document).on('turbolinks:load', function () {
    let outcome_selects = $('.outcome-indicator-select')
    let date_fields = $('.outcome-indicator-date')
    let percentage_fields = $('.outcome-indicator-percentage')
    outcome_selects.each((index, select) => {
        let selected = select.value
        if(selected) {
            ToggleRequiredFields(selected, date_fields, percentage_fields)
        }
    })

    outcome_selects.on('change', (e) => {
        const outcome_id = e.target.value;
        ToggleRequiredFields(outcome_id, date_fields, percentage_fields)
    });
});

const disableSelect = (field, disabled) => {
    field.prop('disabled', disabled)
    if(disabled) {
        field.addClass('disabled')
        field.val('')
    } else {
        field.removeClass('disabled')
    }
}

const ToggleRequiredFields = (outcome_id, date_fields, percentage_fields) => {
    const url = `${window.location.origin}/nl/outcome_indicators/${outcome_id}/poll_requirements`;

    $.ajax({
        type: 'GET',
        url: url,
        data: {},
        success: function(json) {
            disableSelect(date_fields, !json["date_required"]);
            disableSelect(percentage_fields, !json["percentage_required"]);
            if(json['percentage_required']) {
                replacePercentageOptions(percentage_fields, json['percentage_options'])
            }
            if(json['date_required']) {
                let patientRecordDate = $('#patient-record-reboarding-date').data('date')
                date_fields.val(patientRecordDate)
            }
        }
    });
}

const replacePercentageOptions = (select_fields, options) => {
    let patientRecordPercentage = $('#patient-record-reintegration-percentage').data('percentage')
    let optionsItems = options.map(option => {
        if(patientRecordPercentage == option) {
            return `<option selected="selected" value="${option}"> ${option}% </option>`
        } else {
            return `<option value="${option}"> ${option}% </option>`
        }

    })
    select_fields.html(optionsItems).change();
}
