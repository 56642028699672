$(document).on('turbolinks:load', function () {
    let status_form_field = $('#patient_record_status')
    let caregiver_form_field = $('#patient_record_caregiver_id')
    status_form_field.change(function(){
        let selection = $('#patient_record_status option:selected').val();
        if(selection == 'pending') {
            caregiver_form_field.prop( 'disabled', true );
            caregiver_form_field.val("").change();
        } else {
            caregiver_form_field.prop( 'disabled', false );
            caregiver_form_field.prop( 'checked', false );
        }
    });
});