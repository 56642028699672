const indexTableEventListeners = () => {
    let table = document.querySelector('table.sortable')
    let attribute = table?.id
    let search = document.querySelector('input[name="search_string"]')?.value
    let dataAttributeString = getDataVariableString(table, search)
    const ajaxTarget = $(".ajax-target");
    let previousDirection = ajaxTarget.attr('data-direction')
    let previousSort = ajaxTarget.attr('data-sort')
    setArrowDirectionClass(previousSort, previousDirection)
    document.querySelectorAll('table.sortable>thead>tr>*').forEach((th, index) => {
            let name = th.dataset?.name

            let direction = ''
            if (previousSort === name) {
                previousDirection === 'DESC' ? direction = 'ASC' : direction = 'DESC'
            } else {
                direction = 'ASC'
            }
            if (name) {
                th.classList.add('filter-attribute', 'ajax-sort')
                th.addEventListener('click', () => {
                    const from = new URLSearchParams(window.location.search).get('from');
                    const to = new URLSearchParams(window.location.search).get('to');
                    if (from != null && to != null ) {
                        dataAttributeString = dataAttributeString + `&urlfrom=${from}`+ `&urlto=${to}`
                    }
                    let dataString = dataAttributeString ? `${dataAttributeString}&sort=${name}&direction=${direction}` : `sort=${name}&direction=${direction}`
                    $.ajax({
                        url: attribute,
                        data: dataString,
                        type: 'get',
                        success: (event) => {
                            ajaxTarget.html(event)
                            ajaxTarget.attr('data-sort', name)
                            ajaxTarget.attr('data-direction', direction)
                            indexTableEventListeners()
                        }
                    })
                })
            }
            index++
        }
    )
}

const setArrowDirectionClass = (attribute, direction) => {
    if (direction && attribute) {
        $(`[data-name="${attribute}"]`).addClass(`${direction.toLowerCase()}`)
    }
}

const getDataVariableString = (element, search_param) => {
    let dataAttributeString = ''
    let dataAttributes = {...element?.dataset}
    let counter = 0
    for (let j in dataAttributes) {
        if (counter === 0) {
            dataAttributeString += `${j}=${dataAttributes[j]}`
        } else {
            dataAttributeString += `&${j}=${dataAttributes[j]}`
        }
        counter++
    }
    if (search_param) dataAttributeString ? dataAttributeString += `&search_string=${search_param}` : dataAttributeString += `?search_string=${search_param}`

    return dataAttributeString
}

export default indexTableEventListeners