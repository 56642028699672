import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    static targets = [ "output" ]
    connect() {
        this.sortable = Sortable.create(this.element, {
            group: 'shared',
            animation: 150,
            direction: 'horizontal',
            onEnd: this.end.bind(this)
        })
    }

    end(_event) {
    }
}
