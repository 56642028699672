$(document).on('turbolinks:load', function () {
    const form = $(".available-user-ajax-form");
    const modal = $('#AvailableUsersModal');
    const target = $(".available-user-modal-content");

    form.on("ajax:success", (event) => {
        const [_data, _status, xhr] = event.detail;
        target.html(xhr.responseText);
        modal.modal('show');
    });

    modal.on("ajax:success", (event) => {
        const [_data, _status, xhr] = event.detail;
        target.html(xhr.responseText);
    });

    form.on("ajax:error", (event) => {
        const [_data, status, _xhr] = event.detail;
        target.html("<h1>" + status + "</h1>");
    });
});