$(document).on('turbolinks:load', function () {
    let company_representative_field = $('#new_user input[data="company_representative"]')
    let companies_field = $('#new_user div[id="companies_field"]')

    showCompanies();

    company_representative_field.change(function () {
        showCompanies();
    });

    function showCompanies() {
        if (company_representative_field.is(":checked")) {
            companies_field.removeClass('d-none');
        } else {
            companies_field.addClass('d-none');
            $('#new_user select[id="user_company_ids"] > option').prop('selected', false);
        }
    }
});