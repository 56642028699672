$(document).on('turbolinks:load', function () {
    const form = $('#questionnaire-form');

    form.on('change', function () {
        $.ajax({
            url: '/participations/save_answer',
            data: form.serialize(),
            type: 'post',
        })
    })

})