import indexTableEventListeners from './index-table-event-listeners'

$(document).on('turbolinks:load', function () {
    const table = $(".ajax-target");
    const form = $(".ajax-form");

    form.on("ajax:success", (event) => {
        const [_data, _status, xhr] = event.detail;
        table.html(xhr.responseText);
        indexTableEventListeners()
    });

    form.on("ajax:error", (event) => {
        const [_data, status, _xhr] = event.detail;
        table.html("<h1>" + status + "</h1>");
    });

    $("form :input").on('change input', debounce(
        () => {form.find('input[type=submit]').click()}, 250)
    )
})

function debounce(func, wait) {
    let timeout;
    return () => {
        var context = this, args = arguments;
        var later = () => {
            timeout = null;
            func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}