$(document).on('turbolinks:load', function () {
    const save_trajectory_button = $('#trajectory-save')
    save_trajectory_button.on('click', (_event) => {
        document.querySelectorAll('div[data-controller="right"]').forEach(item => {
            const block_id = item.id.match(/\d+$/);
            const block_order = item.getAttribute('order');
            const questionnaire_ids = $('#trajectory_trajectory_building_blocks_attributes_' + block_order + '_questionnaire_ids');
            const questionnaires = document.getElementById('right' + block_id).getElementsByClassName('questionnaire-id');
            let questionnaire_values = [];

            for (let i = 0; i < questionnaires.length; i++) {
                questionnaire_values.push(questionnaires[i].dataset.questionnaireId);
            }
            questionnaire_ids.val(questionnaire_values);
        });
    })
})