import indexTableEventListeners from "./index-table-event-listeners";

$(document).on('turbolinks:load', function () {
    const input = $(".dash-search");
    const route = input.data('route');
    const target = $("#search-results");

    input.on('change input', debounce(
        () => {
            $.ajax({
                url: route,
                data: { search: input.val()},
                type: 'get',
                success: function(data) {
                    if(data) {
                        target.html(data)
                        target.removeClass('d-none')
                    } else {
                        target.html(null)
                        target.addClass('d-none')
                    }
                }
            })
        })
    )
})

function debounce(func, wait) {
    let timeout;
    return () => {
        var context = this, args = arguments;
        var later = () => {
            timeout = null;
            func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}
