$(document).on('turbolinks:load', function () {
    let select = $('select#patient_record_requested_role_id')
    const route = select.data('route');

    select.on('change', () => {
        $.ajax({
            url: route,
            data: { role: select.val()},
            type: 'get'
        })
    })
})