$(document).on('turbolinks:load', function () {
  to_truncate = $('.truncate')
  to_truncate.each((i, el) => {
    if (isEllipsisActive(el)) {
      $(el).attr('title', $(el).text());
      $(el).attr('data-bs-placement', 'bottom');
      $(el).attr('data-bs-toggle', 'tooltip');
    }
  })
  $('[data-bs-toggle="tooltip"]').tooltip()

  function isEllipsisActive(el) {
    return el.scrollWidth !== el.offsetWidth ? el.scrollWidth > el.offsetWidth : checkRanges(el);
  }

  function checkRanges(el) {
    const range = new Range();
    range.selectNodeContents( el );

    const range_rect = range.getBoundingClientRect();
    const el_rect = el.getBoundingClientRect();
    if( range_rect.right > el_rect.right ) {
      return true;
    }
    el.classList.add( "text-overflow-ellipsis" );
    const rects_ellipsis = range.getClientRects();
    el.classList.add( "text-overflow-clip" );
    const rects_clipped = range.getClientRects();
    el.classList.remove( "text-overflow-ellipsis" );
    el.classList.remove( "text-overflow-clip" );
    return rects_clipped.length !== rects_ellipsis.length;
  }

})
