$(document).on('turbolinks:load', function () {
    let trajectory_building_block_field = $('#counseling_session_trajectory_building_block_id');
    let warning_target = $('.warning-target')
    let guidance_target = $('.guidance-target')

    const set_messages = function(url, data) {
        $.ajax({
            type: 'GET',
            url: url,
            data: data,
            success: function(json) {
                set_warning(json)
                set_guidance(json)
            }
        });
    };

    const set_warning = (json) => {
        if (json['show_message']) {
            warning_target.html("<h6 class=\"text-danger\">" + "<i class=\"las la-exclamation-triangle\"></i>" + json['message']+ "</h6>");
        } else {
            warning_target.html(json['message']);
        };
    }

    const set_guidance = (json) => {
        if(json['show_text']) {
            guidance_target.html(`<p>${json['block_name']}: ${json['text']}</p>`)
        } else {
            guidance_target.html(json['text'])
        }
    }

    trajectory_building_block_field.change(function(){
        const building_block_id = trajectory_building_block_field.val();
        const base_url = $(location).attr('pathname');
        const url =  base_url + '/session_messages';
        const patient_record_id = base_url.substring(base_url.lastIndexOf('/') + 1);
        const data = { 'block_id': building_block_id, 'patient_record_id': patient_record_id };
        if (building_block_id) {
            set_messages(url, data);
        };
    });
});