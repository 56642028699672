import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = [ "output" ]
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  end(_event) {
    let blocks = document.getElementsByClassName('block-id');
    let block_ids = []
    for (let i = 0; i < blocks.length; i++) {
      block_ids.push(blocks[i].dataset.blockId)
    }
    block_ids = JSON.stringify(block_ids)
    const base_url = $(location).attr('pathname');
    const url =  base_url + '/move';
    const trajectory_id = base_url.substring(base_url.lastIndexOf('/') + 1);
    $.ajax({
      type: 'PATCH',
      url: url,
      data: { id:trajectory_id, block_ids: block_ids }
    });
  }
}
