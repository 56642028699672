$(document).on('turbolinks:load', function () {
    $.each(Cookies.get(), (name) => {
        $(`a[data-name="${name}"]`).attr('aria-expanded', 'true')
        $(`ul[data-name="${name}"]`).addClass('show')
    })
    let togglers = $('a.dropdown-toggle')
    var names = []
    togglers.each(function () {
        names.push($(this).data('name'));
    })

    togglers.on('click', (event) => {
        names.forEach(name => Cookies.remove(name))
        let name = $(event.target).data('name')
        if (Cookies.get(name) == undefined) {
            $(`a[data-name]`).attr('aria-expanded', 'false')
            $(`ul[data-name]`).removeClass('show')
            Cookies.set(name, 'shown')
        } else {
            Cookies.remove(name)
        }
    })
})