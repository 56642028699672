$(document).on('turbolinks:load', function () {
    $('select[name="filters[caregiver]"]').select2({
        theme: "bootstrap4"
    });

    $('select[name="filters[company]"]').select2({
        theme: "bootstrap4"
    });

    $('select[name="patient_record[company_id]"]').select2({
        theme: "bootstrap4"
    });

    $('select[name="patient_record[caregiver_id]"]').select2({
        theme: "bootstrap4"
    });

    $('select[name="patient_record[caregiver]"]').select2({
        theme: "bootstrap4",
        dropdownParent: $('#roleReferModal .modal-content')
    });
})