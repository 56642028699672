import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "questionnaire",
    "section",
    "nextButton",
    "currentSection",
    "submitUrl",
  ];

  async connect() {
    this.questionnaireRef = this.element.id;
    const url = new URL(window.location.href);
    this.patientRecordId = url.searchParams.get("patient_record");
    await this.fetchQuestionnaire();
    this.optionalAttributes = this.fetchOptionalAttributes();
    await this.importSections();
    await this.showSection();
    this.errorTranslations = {
      en: "Provide an answer to all the questions indicated with a purple box",
      nl: "Geef een antwoord op alle vragen die met een paars vakje zijn aangegeven",
      fr: "Veuillez répondre à toutes les questions indiquées par une case violette",
    };
  }

  async fetchQuestionnaire() {
    await fetch(
      `questionnaires/${this.questionnaireRef}/data/${this.patientRecordId}`,
    )
      .then((response) => response.json())
      .then((data) => {
        this.questionnaire = data.questionnaire;
        this.language = data.language;
      });
  }

  async importSections() {
    const sectionsHtml = await this.getSectionViews(
      this.getQuestionnaireSections(),
    );
    const sectionsDiv = document.getElementById("sections");
    sectionsDiv.innerHTML = sectionsHtml;
  }

  addErrorToolTip() {
    let tooltip = document.getElementById("error-tooltip");
    tooltip.innerHTML = `<span class=\"badge badge-xl rounded-pill bg-danger\">${
      this.errorTranslations[this.language]
    }</span>`;
  }

  removeErrorTooltip() {
    let tooltip = document.getElementById("error-tooltip");
    tooltip.innerHTML = "";
  }

  async submitSection() {
    this.removeErrorBorders();
    const [sectionData, incompleteReferences] = this.fetchSectionData();

    if (incompleteReferences.length === 0) {
      this.removeErrorTooltip();
      this.storeSectionAnswers(sectionData);
      await this.showSection();
    } else {
      this.addErrorBorders(incompleteReferences);
      this.addErrorToolTip();
    }
  }

  async showSection() {
    const nextSection = this.getNextIncompleteSection();
    if (this.isLastSection()) {
      this.updateNextButtonText();
    }
    this.currentSection = nextSection;
    if (nextSection) {
      const targetReference = nextSection.reference;
      const divs = document.querySelectorAll(".section");
      divs.forEach((div) => {
        if (div.dataset.reference !== targetReference) {
          div.style.display = "none";
        } else {
          div.style.display = "";
        }
      });
    } else {
      await this.uploadAnswers();
    }
  }

  getNextIncompleteSection() {
    const sections = this.getQuestionnaireSections();
    const completedSections = this.getCompletedSections();
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (!completedSections.includes(section.reference)) {
        return section;
      }
    }
    return null;
  }

  isLastSection() {
    return (
      this.getQuestionnaireSections().length -
        this.getCompletedSections().length <=
      1
    );
  }

  updateNextButtonText(){
    const button_text = {
      en: "Finish",
      nl: "Afronden",
      fr: "Terminer",
    };
    this.nextButtonTarget.innerHTML = button_text[this.language];

  }

  addErrorBorders(incompleteReferences) {
    incompleteReferences.forEach((element) => {
      const card = document.getElementById(element).closest(".card");
      card.classList.add("border-danger");
    });
  }

  removeErrorBorders() {
    document
      .getElementById("sections")
      .querySelectorAll(".border-danger")
      .forEach((element) => {
        element.classList.remove("border-danger");
      });
  }

  getCompletedSections() {
    const storedResults = JSON.parse(
      localStorage.getItem(this.questionnaire.reference),
    );
    if (!storedResults) {
      return [];
    }
    return Object.keys(storedResults);
  }

  getQuestionnaireSections() {
    return this.questionnaire.questionnaire_sections;
  }

  fetchOptionalAttributes() {
    let result = {};
    for (let section of this.getQuestionnaireSections()) {
      for (let question of section.questions) {
        result[question.reference] = question.optional;
      }
    }
    return result;
  }

  async getSectionViews(sections) {
    const jsonData = JSON.stringify(sections);
    const response = await fetch(
      `questionnaires/${this.questionnaireRef}/sections/${this.patientRecordId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken(),
        },
        body: jsonData,
      },
    );
    return response.text();
  }

  formatAnswerJson() {
    const answerData =
      localStorage.getItem(this.questionnaire.reference) || "{}";
    const answerJson = {
      answers: answerData,
      version: this.questionnaire.version,
    };
    return JSON.stringify(answerJson);
  }

  async uploadAnswers() {
    const answer = this.formatAnswerJson();
    const submitUrl = this.nextButtonTarget.getAttribute("data-submit-url");
    const response = await fetch(`${submitUrl}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken(),
      },
      body: answer,
    });
    localStorage.removeItem(this.questionnaire.reference);
    window.location.href = response.url;
  }

  fetchForms() {
    const divs = document.getElementById("sections").children;
    return Array.from(divs)
      .filter((div) => getComputedStyle(div).display !== "none")
      .map((div) => Array.from(div.querySelectorAll("form")))
      .filter((form) => form)
      .flat();
  }

  fetchScaleAnswer(form) {
    const selectedOption = form.querySelector('input[type="radio"]:checked');
    return selectedOption ? selectedOption.value : null;
  }

  fetchSectionData() {
    const forms = this.fetchForms();
    let sectionData = {};
    const optionalAttributes = this.optionalAttributes;
    let IncompleteReferences = [];
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      const formType = form.getAttribute("class");
      const reference = form.id;
      if (formType.includes("scale_option")) {
        parseInt(this.fetchScaleAnswer(form));
        sectionData[reference] = parseInt(this.fetchScaleAnswer(form));
      }
      let answer = sectionData[reference];
      if (
        !optionalAttributes[reference] &&
        (isNaN(answer) || answer === null)
      ) {
        IncompleteReferences.push(reference);
      }
    }
    return [sectionData, IncompleteReferences];
  }

  storeSectionAnswers(sectionData) {
    const jsonSection = { [this.currentSection.reference]: sectionData };
    const documentName = this.questionnaire.reference;
    const existingData = JSON.parse(localStorage.getItem(documentName) || "{}");
    const mergedData = Object.assign(existingData, jsonSection);
    localStorage.setItem(documentName, JSON.stringify(mergedData));
  }

  async nextSection() {
    await this.submitSection();
  }
}
