// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'css/site'
import 'simplebar/dist/simplebar.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css';

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('simplebar')
require("bootstrap")
require("select2")
const jQuery = require("jquery");
window.Cookies = require('js-cookie')

import "chartkick/chart.js"

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
window.bootstrap = require('bootstrap/')
require('./navbar_toggles')
require('./navbar_menu_toggles')
require('./auto-complete-dash-search')
require('./example-trajectory-select')
require('./row_buttons')
require('./truncate')
require('./caregiver_features_form')
require('./disable_caregiver_select_record')
require('./available_users')
require('./patient_record_visibility')
require('./early_answer_save')
require('./replace_indicator_options')
require('./replace_select_options')
require('./users_companies_field')
require('./progress_bar_active_scroll')
require('./index-table-event-listeners')
require('./ajax_form_handler')
require('./questionnaires_order')
require('./session_limit')
require("./sortable-table-header")
require('./select2')
require("@nathanvda/cocoon")
require("./cookie-banner")
require("./referable-users")
require("./zendesk_widget")
require("./show_anchor_tab")
require("./outcome_termination_check")
require("./patient_record_status_form_field_handler")
require("./company_outcome_detail_form_handler")



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

require("trix")
require("@rails/actiontext")