$(document).on('turbolinks:load', function () {
    const ajaxTarget = $(".ajax-target");
    const trajectorySelect = $('.example-trajectory-select')

    trajectorySelect.on('change', function () {
        let selected = $(this).val()
        $.ajax({
            url: '/trajectories/new',
            data: `example_trajectory=${$(this).val()}`,
            type: 'get',
            success: (event) => {
                console.log(event)
                ajaxTarget.html(event)
            }
        })
    })

})